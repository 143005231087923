import axios from 'axios'
import config from '../config'
//const APPOINTMENT_API_URL = config.appointment_api_url;
const namespaced = true

import {store} from "@/store/index";

const state = {
    login: {success: false},


    user: {},

}
const actions = {
    GET_USERDATA: async function () {
        let url = 'https://core.uba.documedias.com/';
        let dgho_token = localStorage.getItem('dgho_token2022')
        if (dgho_token) {
            console.log('getUser', dgho_token);
            var bodyFormData = new FormData();
            bodyFormData.append('token', dgho_token);
            bodyFormData.append('type', 'jwt');
            bodyFormData.append('conf', config.conference);
            bodyFormData.append('provider', 'dgho');
            try {
                let data = await axios({
                    method: "post",
                    url: url,
                    data: bodyFormData,
                    headers: {"Content-Type": "multipart/form-data", 'x-api-key': undefined},
                })
                return data
            } catch (err) {
                console.log('get', err)
            }
        } else {
            return null;
        }
    },
    GET_USERACTIVE: function () {
        return axios.get(config.auth_url + 'api/user/active', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })
    },
    GET_ROLE: function (payload) {
        return axios.get(config.auth_url + 'api/role/isAllowed/' + payload.role + '/' + payload.conference, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })
    },
    GET_LOGIN: function () {
        return this.state.login;
    },
    logout: () => {
        localStorage.removeItem('x-api-key');
        this.$route.push('/login');
    },

    GET_APPOINTMENT_ROLE: async function () {
        let userRequest = await store.dispatch("auth/GET_USERDATA");
        let appointmentRoles = {isExhibitor: false, isConsultant: false, exhibitorName: null}

        let exhibitorsArray = await store.dispatch('database/getItems', {table: 'exhibitors'}, {root: true});

        if (exhibitorsArray) {  //if there is exhibitors
            if (userRequest) {

                let filteredData = exhibitorsArray.filter(ex => ex.email == userRequest.data.emailBusiness);  //looks for the exhibitor of this consultant
                let userExhibitor = filteredData[0]//CHANGE THIS AFTER TESTING

                if (userExhibitor && userExhibitor.name != null) {
                    appointmentRoles.isExhibitor = true;
                    appointmentRoles.exhibitorName = userExhibitor.name;
                }
            }
        }

        if (userRequest) {
            let userConsultant = [];//(await axios.post(APPOINTMENT_API_URL + "consultants/getconsultantbyauthuser/"+userRequest.data.id)).data; //gets consultant from user

            if (exhibitorsArray && userConsultant[0]) { //if there is exhibitors
                let exhibitorFromThisConference = exhibitorsArray.filter(ex => ex.id == userConsultant[0].exhibitor_id);  //looks if the consultant exhibitor is in the exhibitor list of this confernece

                if (exhibitorFromThisConference.length > 0) {

                    if (userConsultant) {
                        appointmentRoles.isConsultant = true

                    }
                }
            }
        }


        return appointmentRoles;
    },

}
const mutations = {
    SET_USER: (state, user) => {
        if (user) {
            if (user.extra && user.extra[config.conference]) {
                let extra = user.extra[config.conference];
                //"tn_marker": "1312_eNG21"
                user.user_tn_role = extra.tn_role; //cme oder nicht
                user.user_is_chair = extra.is_chair; //nur am mittwoch
                user.user_tn_marker = extra.tn_marker;
                user.user_tn_number = extra.tn_number;//darf ausstellung sehen
                user.user_tn_status = extra.tn_status;//info für chat
                user.user_tn_category = extra.tn_category;
            }
        }

        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    },
    SET_LOGIN: (state, login) => {
        // Vue.set(state, 'login', login)
        state.login = login
    },
}
const getters = {
    getLogin: (state) => {
        return state.login;
    },
    user: (state) => {
        return JSON.parse(localStorage.getItem('user', state.user))
    }
}


export const auth = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
